import useWallet from "@/store/useWallet"; // 引入钱包状态管理
import walletimg from "@/image/walletimg.png"; // 引入钱包图标
import downarrow from "@/image/downarrow.png"; // 引入向下箭头图标
import href from "@/image/href.png"; // 引入外部链接图标
import { useState } from "react";
import { Button, Dropdown, MenuProps, Modal } from "antd"; // 引入 Ant Design 组件库
import { cheengeNumber, mobileHidden } from "@/utils"; // 引入工具函数
import logo from "@/image/logo.png"; // 引入 logo 图片
import languageImg from "@/image/languageImg.png"; // 引入语言图标
import { useTranslation } from "react-i18next"; // 引入国际化钩子
import useLanguage from "@/store/useLanguage"; // 引入语言状态管理
import Copy from "@/components/copy"; // 引入复制组件
import useHasReferrer from "@/web3Hooks/useUserInfo"; // 引入推荐人信息 Hook
import useBalance from "@/web3Hooks/useBalance"; // 引入余额获取 Hook
import { LoadingOutlined } from "@ant-design/icons";
import { formatUnits } from "ethers"; // 用于格式化余额的工具
import home from '@/image/home.png' // 引入默认 home 图标
import seleHome from '@/image/seleHome.png' // 引入选中状态的 home 图标
import my from '@/image/my.png' // 引入默认 my 图标
import seleMy from '@/image/seleMy.png' // 引入选中状态的 my 图标
import { useLocation, useNavigate } from "react-router-dom" // 引入路由钩子用于获取路径和导航
// 定义语言选项
const languageItems: MenuProps["items"] = [
  { key: "cn", label: "中文" },
  { key: "en", label: "English" },
  { key: "ja", label: "日本語" },
  { key: "ko", label: "한국어" },
];

// 定义 Header 组件
const Header = () => {
  const { t, i18n } = useTranslation(); // 获取国际化相关函数
  const { setLanguage } = useLanguage(); // 获取设置语言的函数
  const { pathname } = useLocation() // 获取当前路径以控制选中状态
  const { getBalanceLod, balance, getBalance } = useBalance(); // 获取余额相关函数和状态
  useHasReferrer(); // 调用 Hook 获取推荐人信息
  // 定义底部导航栏的数据
  const tabData = [
    {
      img: home, // 未选中状态的图标
      seleImg: seleHome, // 选中状态的图标
      name: t('foot.home'), // 导航名称，通过国际化获取
      path: '/' // 路径
    },
    {
      img: my, // 未选中状态的图标
      seleImg: seleMy, // 选中状态的图标
      name: t('foot.my'), // 导航名称，通过国际化获取
      path: '/my' // 路径
    }
  ]
  // 获取钱包状态
  const {
    wallet: { address }, // 获取钱包地址
    connectedWallet, // 连接钱包函数
    breakWallet // 断开钱包函数
  } = useWallet();

  const navigate = useNavigate(); // 初始化路由导航
  const [isModalOpen, setIsModalOpen] = useState(false); // 控制弹窗的状态

  // 打开弹窗并获取主网币余额
  const showModal = () => {
    setIsModalOpen(true);
    getBalance(); // 每次打开弹窗时调用获取余额
  };

  // 关闭弹窗
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // 跳转至指定网址
  const goHref = (url: string) => {
    return () => {
      window.open(url);
    };
  };

  // 切换语言
  const changeLanguage: MenuProps["onClick"] = ({ key }) => {
    i18n.changeLanguage(key); // 切换语言
    setLanguage(key); // 设置语言到状态
  };

  return (
    <div className="flex items-center justify-between box-border p-4 bg-[#242930] shadow-header md:px-14">
      {/* 左侧 Logo 和标题 */}
      <div className="flex items-center">
        <img
          onClick={() => { navigate("/") }} // 点击 Logo 跳转到主页
          className="w-[35px] mr-[6px] cursor-pointer md:w-[60px] "
          src={logo}
          alt=""
        />
        <span className="text-white text-base font-semibold md:mr-20">AIM</span>
        <div className=" items-center hidden md:flex">
          {tabData.map((item, index) => {
            return (
              <div
                key={index}
                className="flex  items-center cursor-pointer mr-5"
                onClick={() => { navigate(item.path) }} // 点击导航项后跳转到对应路径
              >
                <img
                  className="w-[30px]"
                  src={pathname === item.path ? item.seleImg : item.img} // 根据路径切换图标显示
                  alt=""
                />
                <p
                  className={`text-xs ${pathname === item.path ? 'text-[#FFB040]' : 'text-[#A7A9AC]'}`} // 根据路径切换文字颜色
                >
                  {item.name} {/* 显示导航名称 */}
                </p>
              </div>
            )
          })}
        </div>
      </div>

      {/* 右侧功能按钮 */}
      <div className="flex items-center">
        {/* 语言选择下拉框 */}
        <Dropdown
          trigger={['click']}
          menu={{ items: languageItems, onClick: changeLanguage }} // 点击语言选项触发 changeLanguage 函数
          placement="bottomRight"
          arrow
        >
          <div className="box-border flex items-center border border-[#444444] p-1 h-[34px] rounded-md mr-3 cursor-pointer">
            <img src={languageImg} className="w-6 h-6 mr-1 cursor-pointer" alt="" />
            <img className="w-4 h-4" src={downarrow} alt="" />
          </div>
        </Dropdown>

        {/* 钱包按钮 */}
        {address ? (
          // 已连接钱包的显示
          <div className="flex items-center cursor-pointer">
            <div
              className="flex items-center justify-between rounded-3xl px-1 border border-[#444444] h-[34px]"
              onClick={showModal} // 点击显示钱包弹窗
            >
              <img className="w-[26px] h-[26px] mr-[6px]" src={walletimg} alt="" />
              <img className="w-4 h-4" src={downarrow} alt="" />
            </div>
          </div>
        ) : (
          // 未连接钱包时的连接按钮
          <Button
            className="box-border rounded-md px-2 text-sm h-[34px] flex items-center justify-center text-white"
            onClick={() => { connectedWallet(); }} // 点击连接钱包
          >
            {t('header.connectWallet')}
          </Button>
        )}
      </div>

      {/* 钱包弹窗 */}
      <Modal
        footer={null} // 不显示底部按钮
        title={t('header.myWallet')} // 弹窗标题
        open={isModalOpen} // 弹窗是否打开
        onOk={handleOk} // 确认关闭
        onCancel={handleOk} // 取消关闭
      >
        <div className="pt-5 whitespace-nowrap text-white">
          <div className="flex items-center justify-between text-[12px] text-[#A7A9AC]">
            {t('header.walletAddress')} {/* 钱包地址标题 */}
          </div>
          <div className="box-border mt-[7px] flex items-center justify-between border border-[#444444] py-[10px] px-[6px] rounded-md">
            <span>{mobileHidden(address, 25, 5)}</span> {/* 显示部分隐藏的钱包地址 */}
            <Copy msg={address} className="w-[14px] h-auto" /> {/* 复制钱包地址按钮 */}
          </div>
          <div className="flex items-center justify-between mt-[15px] text-xs">
            <span className="text-[#A7A9AC]">USDT{t('header.balance')}</span>
            <span className="text-white font-medium">{getBalanceLod ? <LoadingOutlined />
              : cheengeNumber(formatUnits(balance), 2)}</span> {/* 显示BNB余额 */}
          </div>
          <Button
            className="mt-3"
            block
            onClick={() => {
              breakWallet(); // 断开钱包连接
              handleOk(); // 关闭弹窗
              navigate("/"); // 返回主页
            }}
          >
            {t('header.exitWallet')}
          </Button>
          <div
            className="flex items-center justify-center mt-3 cursor-pointer"
            onClick={goHref("https://bscscan.com/")} // 点击跳转至 Bscscan
          >
            <span className="text-grayA8 text-xs">View on Bscscan</span>
            <img className="w-3 h-3 ml-2" src={href} alt="" />
          </div>
        </div>
      </Modal>
    </div >
  );
};

export default Header; // 导出 Header 组件