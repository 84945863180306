import { Interface, InterfaceAbi } from "ethers";
const erc20Abi = require("./json/erc20.json");
const MyStaking = require("./json/MyStaking.json");
console.log("环境", process.env.REACT_APP_ENV);
const AIMTokenTest = require("./json/AIMTokenTest.json");
const contractAll: {
  [key: number]: {
    [key: string]: { address: string; abi: Interface | InterfaceAbi };
  };
} = {
  56: {
    erc20: { abi: erc20Abi, address: "" },
    myStaking: { abi: MyStaking, address: "" },
    AIMTokenTest: { abi: AIMTokenTest, address: "" },
  },
};
if (process.env.REACT_APP_ENV === "development") {
  contractAll[56].erc20.address = "0x55d398326f99059fF775485246999027B3197955";
  contractAll[56].myStaking.address =
    "0xCd83d8e12B2D6D8A215b48392Ca4E80B764bc4dB";
  contractAll[56].AIMTokenTest.address =
    "0x73ff1A844f85247d984Cd33AD3002cce80d5E976";
} else if (process.env.REACT_APP_ENV === "test") {
  contractAll[56].erc20.address = "0x55d398326f99059fF775485246999027B3197955";
  contractAll[56].myStaking.address =
    "0xCd83d8e12B2D6D8A215b48392Ca4E80B764bc4dB";
  contractAll[56].AIMTokenTest.address =
    "0x73ff1A844f85247d984Cd33AD3002cce80d5E976";
} else {
  contractAll[56].erc20.address = "0x55d398326f99059fF775485246999027B3197955";
  contractAll[56].myStaking.address =
    "0xCd83d8e12B2D6D8A215b48392Ca4E80B764bc4dB";
  contractAll[56].AIMTokenTest.address =
    "0x73ff1A844f85247d984Cd33AD3002cce80d5E976";
}

export default contractAll;
